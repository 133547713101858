<template>
  <div>
    <div class="context">
      <LogOn class="Myopacity" :animation="animation"/>
      <div class="DSIntro" @click="HideAnimation">
        <div class="btnSIntro">
          Saltar Intro
        </div>
      </div>
    </div>

    <div class="area" v-if="ShowCSS">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
</template>

<script>
import LogOn from "../components/loginCards/LogOn.vue";
import $ from "jquery";

export default {
  name: "Login",
  components: {
    LogOn,
  },
  data() {
    return {
      ShowCSS: true,
      animation: false,
    };
  },
  watch:{
    animation: function () {
      // if (!this.animation) {
      //   $(".context").css({ overflow: "auto"})
      // } else {
      //   $(".context").css({ overflow: "hidden"})
      // }
    }
  },
  created(){
  },
  mounted() {
    this.$root.logOut()
    var vmx = this;
    this.HideAnimation()
  },
  methods: {
    HideAnimation() {
      this.animation = false;
      this.ShowCSS = true;
      $(".DSIntro").remove();
      $(".Myopacity")
        .css({ opacity: "1", display: "none" })
        .fadeIn();
    },
  },
};
</script>

<style scoped>
.Myopacity {
  opacity: 0;
}
.DSIntro {
  position: absolute;
  top: calc(100% - 70px);
  left: calc(100% - 150px);
  z-index: 9;
}
.DSIntro:hover .btnSIntro {
  cursor: pointer;
  border: 3px solid rgb(255, 62, 62);
  color: rgb(255, 62, 62);
}
.btnSIntro {
  padding: 10px;
  border: 3px solid #000;
  font-weight: bold;
  border-radius: 5px;
}

.context {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0vh;
  z-index: 99;
}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}

.area {
  background: #40b3ec;
  background: -webkit-linear-gradient(270deg, #40b3ec, #125679);
  width: 100%;
  height: 100vh;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
</style>
