<template>
  <div class="BackLogOn pa-md-1 px-1 py-8">
    <v-expand-x-transition>
      <LoginCard v-if="view" :view="view" />
    </v-expand-x-transition>
    <v-expand-x-transition>
      <SinginCard v-if="!view" :view="view" />
    </v-expand-x-transition>
  </div>
</template>

<script>
import LoginCard from "./LogInCard.vue";
import SinginCard from "./SingInCard.vue";
import $ from "jquery";

const agentOptions = {
  rejectUnauthorized: false,
  //ca: "XXXXXXXX"
};

export default {
  components: {
    LoginCard,
    SinginCard,
  },
  data() {
    return {
      view: true,
    };
  },
  mounted() {
    
  },
  created: function () {
    
  },
};
</script>

<style scoped>
.BackLogOn {
  display: flex;
  min-height: 100vh;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.Tcenter {
  padding: 10px;
  text-align: center;
}
</style>
